import { _ } from "vue-underscore";
export default {
	data: () => ({
		topdeal: {
			id: 0,
			logo_url: "",
			destination_pair:"",
			destination: "",
			description: "",
			price: "",
			logo_url: "",
			destination_type: "",
			apiUrl: process.env.VUE_APP_API_URL
		},
		imageFile: null,
		error: "",
		backUrl: "/topdeal",
		destinationList: [],
    loading: false,
		apiUrl: process.env.VUE_APP_API_URL
	}),
	methods: {
		resetForm() {
			this.$refs.observer.reset();
			this.topdeal = {
				id: 0,
				logo_url: "",
				destination: "",
				description: "",
				price: "",
			};
		},
		validateTopdeal() {
			this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
			this.topdeal.id > 0 ? this.update() : this.add();
		},
		update() {
			let _vm = this;
			let fd = new FormData();
			if (_vm.topdeal.logoImage) {
				fd.append("logoImage", _vm.topdeal.logoImage);
			}
			Object.keys(_vm.topdeal).forEach(key => {
				fd.append(key, _vm.topdeal[key]);
			});
			fd.append("_method", "PATCH");
			this.axios
				.post("/top-deal/" + this.topdeal.id, fd)
				.then(function () {
					//_vm.topdeal = response.data.data.data;
					_vm.$router.push(_vm.backUrl);
				});
		},

		add() {
			let _vm = this;
			let fd = new FormData();
			if (_vm.topdeal.logoImage) {
				fd.append("logoImage", _vm.topdeal.logoImage);
			}
			Object.keys(_vm.topdeal).forEach(key => {
				fd.append(key, _vm.topdeal[key]);
			});
			this.axios
				.post("/top-deal", fd)
				.then(function (response) {
					_vm.$router.push(_vm.backUrl);
				});
		},
		getDetail() {
			let _vm = this;
			this.axios
				.get("top-deal/" + this.$route.params.id)
				.then(function (response) {
					_vm.topdeal = response.data.data;
					_vm.onChangeSeoPages();
					// _vm.destinationList = response.data.data;
					if (_vm.topdeal.full_image_url && _vm.topdeal.full_image_url.length > 0) {
						$("#imagePreview").css(
							"background-image",
							"url(" + _vm.topdeal.full_image_url + "?date=" + Date.now() + ")"
						);
						$("#imagePreview").hide();
						$("#imagePreview").fadeIn(650);
					}
				})
				.catch(function () { });
		},
		onChangeSeoPages() {
			const _vm = this
			const selected = _vm.topdeal.destination_type
			this.axios
				.get("/top-deal-destination-list?type=" + (selected ? selected : 0))
				.then(function (response) {
					_vm.destinationList = response.data.data;
				})
				.catch(function () { })
		},
		getQueryString() {
			let queryString = "?search=";
			return queryString;
		},
		selectImage(event) {
			let _vm = this;
			var input = event.target;
			if (input.files && input.files[0]) {
				_vm.topdeal.logoImage = input.files[0];
				var reader = new FileReader();
				reader.onload = function (e) {
					$("#imagePreview").css(
						"background-image",
						"url(" + e.target.result + ")"
					);
					$("#imagePreview").hide();
					$("#imagePreview").fadeIn(650);
				};
				reader.readAsDataURL(input.files[0]);
			}
		},
	},
	mounted() {
		if (this.$route.params.id > 0) {
			this.getDetail();
			// this.onChangeSeoPages();
		}
	}
};
